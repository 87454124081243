<template>
    <div class="notFound">
        <div>
            <img style="width:500px" src="@/assets/404.jpg">
        </div>
        <h1 v-if='auth == 1' style="margin-bottom:20px">抱歉,您暂无访问权限</h1>
        <h1 v-if='auth != 1' style="margin-bottom:20px">抱歉,您要访问的页面丢失了</h1>
        <div v-if='auth != 1' style="margin-bottom:20px">请检查您输入的网址是否正确,请点击一下按钮返回首页</div>
        <a-button v-if='auth != 1' @click="back" type="primary">返回上一页</a-button>
    </div>
</template>

<script>
    export default {
        name:'notFound',
        data() {
            return {
                auth: 0
            }
        },
        created () {
            this.auth = this.$route.query.auth
        },
        methods:{
            back(){
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .notFound{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>